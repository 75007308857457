.layout-wrapper {
    display: flex;
    gap: 40px;
    width: 100%;
    padding: 16px 10%;
    @media screen and (max-width: 933px) {
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding: 16px;
    }
    .item {
        white-space: nowrap;
        flex: 1 1 0;
        width: 0;
        @media screen and (max-width: 933px) {
            width: 100%;
        }

        .item-layout {
            display: flex;
            flex-direction: column;
            gap: 24px;
            .item-header {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                font-weight: 700;
                color: #333333;
            }
        }

        .item-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // gap: 12px;
            width: 100%;
            // max-width: 425px;
            background: #fcfcfd;
            background: linear-gradient(83.59deg, #fcfcfd 36.52%, rgba(252, 252, 253, 0.83) 98.8%);
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            backdrop-filter: blur(32px);
            border-radius: 24px;
            padding: 32px;
            // @media screen and (max-width: 933px) {
            //     padding: 16px;
            // }
            .content-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                font-weight: 700;
                font-size: 14px;
                line-height: 30px;
                color: #777e91;
                padding-top: 12px;
                padding-bottom: 12px;
                padding-left: 12px;
                padding-right: 26px;
                font-weight: 400;
            }

            .content-table {
                display: flex;
                flex-direction: column;
                gap: 4px;
                width: 100%;
                .no-data {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    font-size: 14px;
                    line-height: 30px;
                    background: #f4f5f6;
                    border-radius: 8px;
                    padding: 12px;
                    color: #23262f;
                    // white-space: wrap;
                }
                .table-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    font-size: 14px;
                    line-height: 24px;
                    background: #f4f5f6;
                    border-radius: 8px;
                    padding: 12px;
                    .table-item-label {
                        font-weight: 500;
                        color: #23262f;
                    }
                    .table-item-value {
                        font-weight: 400;
                        color: #656565;
                        padding-right: 10px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                    }
                    .table-item-button {
                        color: #fcfcfd;
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), #329696;
                        border-radius: 90px;
                        padding: 7px 16px;
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: 700;
                        cursor: pointer;
                        border: none;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 6px;
                        &:disabled {
                            background: #e6e6e6;
                            color: #656565;
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }
}

.transfer-modal {
    min-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .hero-header {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
        color: #23262f;
    }

    .input-box {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .input-label {
            font-weight: 700;
            font-size: 12px;
            line-height: 12px;
            text-transform: uppercase;
            color: #b1b5c4;
        }

        .input-address {
            border-radius: 12px;
            padding: 12px 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #23262f;
        }
    }

    .transfer-button {
        width: 100%;
        padding: 16px 0px;
        background: #329696;
        border-radius: 90px;
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 16px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        &:disabled {
            background: #e5e5e5;
            color: #ffffff;
            cursor: not-allowed;
        }
    }
}

.ant-modal-footer {
    display: none;
}
.ant-modal-content {
    border-radius: 24px;
    max-width: 554px;
    width: 100%;
}

.ant-input {
    &:focus {
        box-shadow: none;
    }
}
.ant-input-focused {
    box-shadow: none;
}

.ant-input-affix-wrapper-focused {
    box-shadow: none;
}
