.container{
    align-items: center;
    display: flex;
    height: 100vh;
    text-align: center;
    justify-content: center;
}
.cancel{
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    // max-width: 600px
}

.container .message{
    margin-left: 16px;
}

.cancel-header{
    background-color: #329696;
    padding:32px 16px 32px 16px;
    text-align: center;
    .error{
        background-color: 'red';
    }
    
}
.cancel-header .ant-typography{
    color: white;
}
.cancel-content{
    text-align: center;
    padding:32px 0px 0px 0px
}