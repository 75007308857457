@vp_desktop: 801px;
@vp_tablet: 800px;
.OnDesktop(@rules) {
    @media screen and (min-width: @vp_desktop) {
        @rules();
    }
}

.OnTablet(@rules) {
    @media screen and (max-width: @vp_tablet) {
        @rules();
    }
}

.footer-col {
    text-align: left;
    .OnTablet({
        text-align: left;
    });
    span {
        color: #777e90;
    }
}
.footer-search {
    .OnTablet({
        padding-left: 16px;
        padding-top: 16px
    });
    .footer-search-input {
        width: 100%;
    }
}
.ant-input-affix-wrapper {
    border-radius: 32px;
}
.ant-notification-notice {
    border-radius: 16px;
}
