@import '../node_modules/antd/dist/antd.less';

@primary-color: #329696; 
@media (max-width: 480px) {
  .ant-calendar-picker {
    width: auto !important;
  }
}
.App {
  text-align: center;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App-modal-loading{
  display: flex;
  justify-content: center;
  align-items: center;
}
.code-box-demo {
  text-align: center;
}
.overlay {
  position: relative;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  display: flex;
}
.ant-layout-header,
.ant-layout-footer {
  color: #000;
  background: #fff;
  height: auto;
  padding-left: 10%;
  padding-right: 10%;
}
.ant-layout-header {
  padding: 16px 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 16%);
}
.ant-layout-footer {
  line-height: 1.5;
}
.ant-layout-sider {
  color: #FCFCFD;
  line-height: 120px;
  background: #3ba0e9;
}
[data-theme="dark"] .ant-layout-sider {
  background: #3499ec;
}
.ant-layout-content {
  color: #fff;
  background: #FCFCFD;
  display: flex;
  flex-direction: column;
  align-items: center;
}

> .code-box-demo > .ant-layout + .ant-layout {
  margin-top: 48px;
}

.ant-layout{
  background:white
}
.ant-popover-inner{
 border-radius: 24px;
}
.ant-picker-panel-container{
  border-radius: 8px;
  padding:24px
}

.ant-picker-input>input::placeholder {
  color: #777E90;
}

//common padding
.padding-16{
  padding: 16px !important;
}
.padding-8{
  padding: 8px !important;
}

.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}

.font-12{
  font-size: 12px;
}
.font-14{
  font-size: 14px;
}

.align-start{
  text-align: start;
}
.align-end{
  text-align: end;
}
.align-center{
  text-align: center;
}

.width-100{
  width: 100%;
}
