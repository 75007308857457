@vp_desktop: 801px;
@vp_tablet: 800px;

.OnDesktop(@rules) {
    @media screen and (min-width:@vp_desktop) {
        @rules();
    }
}

;

.OnTablet(@rules) {
    @media screen and (max-width:@vp_tablet) {
        @rules();
    }
}

;

.home-image {
    width: 90%;
    height: 60vh;

    .OnTablet({
        height:40vh;
    });
border-radius: 14px;
object-fit: 'cover';
background-size: cover;
background-position: 50% 50%
}

#background-video {
    height: 60vh;
    width: 90vw;
    object-fit: cover;
    cursor: pointer;

    .OnTablet({
        height:40vh;
    });
background-size: cover;
border-radius: 14px;
}

.button-muted {
    position: absolute;
    right: 6vw;
    top: 70vh;

    .OnTablet({
        top:50vh;
    });
}

.box-search {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 999;
    background: #FCFCFD;
    width: 80%;
    margin-top: 64px;
    padding-left: 16px;
    padding-right: 64px;
    padding-top: 16px;
    padding-bottom: 64px;
    align-items: center;
    background: linear-gradient(83.59deg, #FCFCFD 36.52%, rgba(252, 252, 253, 0.83) 98.8%);
    border: 1px solid #FCFCFD;
    box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1);
    backdrop-filter: blur(32px);
    border-radius: 24px;

    .OnTablet({
        flex-direction: column;
        width: 100%;
        padding-right:16px
    })
}

.box-input {
    display: flex;
    justify-content: space-between;
    flex: 1;
    overflow: hidden;

    .ant-space {
        width: 100%;

        div:last-child {
            width: 100%;
        }
    }
}

.text-input {
    display: flex;
    flex-direction: column;

    .ant-picker-separator {
        opacity: 0;
    }

    .ant-picker-suffix {
        opacity: 0;
    }

    .ant-picker-input {
        margin-left: 16px;
    }
}

.heron-home-label {
    font-size: 24px;

    .OnTablet({
        font-size: auto;
    });

&.left {
    padding-left: 12px;
}
}

.subLabel {
    font-size: 16px;

    &.left {
        padding-left: 12px;
    }

    color:#777E90
}

.calendar-label {
    display: flex;
    align-items: center;
}

.hidden {
    opacity: 0;
}

.guest {
    cursor: pointer;
}

.button-search {
    margin-left: 64px;

    .OnTablet({
        margin-left:0px;
        margin:16px
    });
}

.input-search-wrapper {
    padding: 16px 24px 16px 0px;
    background: linear-gradient(83.59deg, #FCFCFD 36.52%, rgba(252, 252, 253, 0.83) 98.8%);
    border: 1px solid #FCFCFD;
    box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1);
    backdrop-filter: blur(32px);
    border-radius: 20px;

    .input-search-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.home-wrapper {
    .OnTablet({
        background: #FCFCFD;
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 8px
    });
}