@vp_desktop: 801px;
@vp_tablet: 800px;

.OnDesktop(@rules) {
    @media screen and (min-width:@vp_desktop) {
        @rules();
    }
}

;

.OnTablet(@rules) {
    @media screen and (max-width:@vp_tablet) {
        @rules();
    }
}

;

.room-list-container {
    width: 100%;
    // padding-left: 64px;
    // padding-right: 64px
}

.card-avatar {
    border-radius: 14px 14px 0 0;
    // width: 300px;
}

.box-price {
    border-style: solid;
    border-width: 2px;
    border-color: #58C27D;
    border-radius: 4px;
    padding: 4px 8px 4px 8px;
}

.card-room-container {
    width: 100%;
    padding-bottom: 16px;
    cursor: pointer;

    .left-content {
        box-shadow: 0px 40px 32px -24px rgb(15 15 15 / 12%);
        border-radius: 16px;
        padding-bottom: 16px;
        border: 0.5px solid #DADCE2;
        box-shadow: 0px 12px 32px -1px rgb(15 15 15 / 12%);
        width: 100%
    }
}

.sale-available-obsolute {
    position: absolute;
    top: 16px;
    left: 16px;
}

.sale-available-obsolute span {
    background: #FCFCFD;
    padding: 8px 8px 8px 8px;
    border-radius: 4px;
}

.card-room-title {
    color: #141416;
    padding-left: 16px;
}

.card-room-price {
    text-align: right;
    padding-right: 16px;
}

.card-room-recommend {
    color: #353945;
    font-size: 12px;
    padding-left: 16px;
    display: inline-block;
}

.description-room {
    img {
        width: 100%;
    }

    overflow: auto;

    .OnDesktop({
        padding-bottom: 100px;
    });

}

.container-card {
    display: grid;

    .OnDesktop({
        grid-template-columns: 1fr 2fr;
        grid-template-rows: fit-content(0);
    });

.OnTablet({
    grid-template-columns: 1fr;
    grid-template-rows: auto
});
width: 100%;
position: relative;
margin-bottom: 16px;
}

.container-card>* {
    min-width: 0px;
}

.container-card-left {
    .OnDesktop({
        margin-right: 16px;
    });

box-shadow: 0px 40px 32px -24px rgb(15 15 15 / 12%);
border-radius: 16px;
padding-bottom: 16px;
border: 0.5px solid #DADCE2;

box-shadow: 0px 12px 32px -1px rgb(15 15 15 / 12%);

.container-card-left-content {
    width: 100%;

    .ant-image {
        width: 100%;
    }
}

}

.container-card-right {
    overflow-y: auto;

    .OnDesktop({
        margin-left: 32px;
    });

.OnTablet({
    margin-top: 32px;
    max-height: 200px;
}) // background-color:red;
// height: 400px;
}

.select-amount-sticky {
    .OnDesktop({
        position: absolute;
        bottom: 0px;
        left: 33.33%;
        background-color: white;
        width: 66.66%;
        margin-left: 32px;
    });
}