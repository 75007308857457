.heron-vouchers-container {
    padding-left: 10%;
    padding-right: 10%;
}

.qr-encode:hover>.qr-action {
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.qr-encode>.qr-action {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}