.swap-box {
    // position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    max-width: 425px;
    background: #fcfcfd;
    background: linear-gradient(83.59deg, #fcfcfd 36.52%, rgba(252, 252, 253, 0.83) 98.8%);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    backdrop-filter: blur(32px);
    border-radius: 24px;
    padding: 24px;
    .swap-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-label {
            display: flex;
            gap: 12px;
        }
        .header-content {
            display: flex;
            gap: 12px;
        }
    }

    .input-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        .input-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            background-color: #f4f5f6;
            color: #808191;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            width: 100%;
            border-radius: 16px;
            .input-number {
                // ::-webkit-search-decoration {
                //     -webkit-appearance: none;
                // }
                width: 100%;
                background-color: transparent;
                border: none;
            }
        }
        .swap-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(180deg);
        }
        .pay-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .pay-details-label {
                font-size: 14px;
                font-weight: 500;
                color: #333333;
            }
            .pay-details-value {
                font-size: 14px;
                font-weight: 400;
                color: #808191;
            }
        }
    }

    .swap-details {
        display: flex;
        flex-direction: column;
        gap: 14px;
        width: 100%;
        .swap-details-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .swap-details-item-label {
                font-size: 14px;
                font-weight: 500;
                color: #333333;
            }
            .swap-details-item-value {
                font-size: 14px;
                font-weight: 500;
                color: #329696;
            }
        }
    }
    .swap-button {
        width: 100%;
        padding: 12px 0px;
        background: #329696;
        border-radius: 90px;
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        line-height: 24px;
        border: none;
        &:disabled {
            background: #e5e5e5;
            color: #ffffff;
            cursor: not-allowed;
        }
    }
}

.ant-input-number-handler-wrap {
    display: none;
}
.ant-input-number {
    &:focus {
        box-shadow: none;
    }
}
.ant-input-number-focused {
    box-shadow: none;
}

.dot {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #329696;
}
