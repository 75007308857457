@vp_desktop: 801px;
@vp_tablet: 800px;
.OnDesktop(@rules) {
    @media screen and (min-width:@vp_desktop) {
        @rules();
    }
};

.OnTablet(@rules) {
    @media screen and (max-width:@vp_tablet) {
        @rules();
    }
};

.OnMobile(@rules) {
    @media screen and (max-width:@vp_mobile) {
        @rules();
    }
};

.heron-reservation-container {
    border-width: 1px;
    border-style: solid;
    border-color: #F4F5F6;
    border-radius: 16px;
    padding: 32px;
    width: 100%;
    box-shadow: 0px 64px 64px -48px rgb(15 15 15 / 8%);
    background-color: #fff;
}

.heron-reser-gateway-container-info {
    display: grid;

    .OnDesktop({
        grid-template-columns: 1fr 1fr;
        grid-template-rows: fit-content(0);
    });

    .OnTablet({
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    });
    width: 100%;
    position: relative;
    margin-bottom: 16px;
    margin-top: 16px;
}

.heron-reser-gateway-container-info>* {
    min-width: 0px;
}

.heron-reser-gateway-container-left {
    margin-left: 32px;
    overflow-y: auto;
    .OnTablet({
        max-height: 300px
    });
}

.heron-reser-gateway-container-right {
    // margin-left: 32px;
    .OnTablet({
        margin-left: 0px;
        margin-top: 16px
    });

}

.heron-reser-gateway-description-resort {
    img {
        width: 100%;
    }
}