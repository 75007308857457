@vp_desktop: 801px;
@vp_tablet: 800px;

.OnDesktop(@rules) {
    @media screen and (min-width:@vp_desktop) {
        @rules();
    }
}

;

.OnTablet(@rules) {
    @media screen and (max-width:@vp_tablet) {
        @rules();
    }
}

;

.input-with-control {
    .OnDesktop({
        min-width:426px;
    });

.OnTablet({
    width:80vw;
});

width: 100%;
padding: 24px;

.ant-space-item {
    width: 100%;
}

.label {
    font-size: 16px;
}

.sub-label {
    color: #777E90;
    font-size: 12px;
}

.value {
    font-size: 16px;
    padding: 0px 24px 0px 24px;
}

.ant-divider-horizontal {
    margin: 16px 0px
}

.control {
    width: 100%;
    text-align: right;
}

.left {
    text-align: left;
}
}