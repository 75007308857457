.order-gateway {
    padding-left: 10%;
    padding-right: 10%;
}

.heron-order-gateway-atribute {
    width: 100%;
    background: rgba(244, 245, 246, 1);
    border-radius: 16px;
    padding: 16px 16px 16px 16px;
}

.heron-order-gateway-reservation-total {
    width: 100%;
    background: rgba(244, 245, 246, 1);
    border-radius: 16px;
    padding: 8px 0px 8px 0px;
}

.heron-reservation-atribute-item {
    align-items: flex-start;
}

.heron-order-gateway-timeline {
    width: 100%;
    .timeline{
          padding: 16px;
          background: rgba(244, 245, 246, 1);
          border-radius: 16px;
          .event-name{
            max-width: 100%;
          }
    }
}

.heron-resort-image-big {
    display: flex;
    align-items: center;
    justify-content: center;

    .heron-card-avatar {
        border-radius: 16px;
        width: 100%;
        object-fit: cover;
    }

    .ant-image {
        width: 100%;
    }
}

.heron-resort-image-small {
    display: flex;
    align-items: center;
    padding-left: 16px;

    >div {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    img {
        width: 100%;
        border-radius: 8px;
        height: auto;
        object-fit: cover;
    }
}