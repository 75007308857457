@vp_desktop: 801px;
@vp_tablet: 800px;

.OnDesktop(@rules) {
    @media screen and (min-width:@vp_desktop) {
        @rules();
    }
}

;

.OnTablet(@rules) {
    @media screen and (max-width:@vp_tablet) {
        @rules();
    }
}


.heron-payment-gateway-container {
    border-width: 1px;
    border-style: solid;
    border-color: #F4F5F6;
    border-radius: 16px;
    padding: 32px;
    width: 100%;
    box-shadow: 0px 64px 64px -48px rgb(15 15 15 / 8%);
    background-color: #fff;

    .room-container {
        align-items: center;
    }

    .overflow-scroll {
        overflow: auto;
    }

    .room-detail {
        border-radius: 16px;

        .atr {
            color: #777E90;
        }
    }

    img {
        border-radius: 16px;
    }
}

.heron-payment-gateway-atribute {
    width: 100%;
    background: rgba(244, 245, 246, 1);
    border-radius: 16px;
    padding: 16px 16px 16px 16px;

    .heron-payment-gateway-atribute-amount-text {
        font-weight: 500;
        color: #787E92;
    }

    .heron-payment-gateway-atribute-total-text {
        font-weight: 500;
    }

    .heron-payment-gateway-atribute-total-amount {
        padding-left: 8px;
        font-size: 14px;
        font-weight: 700;
    }
}

.heron-payment-gateway-atribute-icon {
    color: #B1B5C3
}

.heron-payment-gateway-input {
    border-radius: 8px;
}

.heron-payment-gateway-qr-container {
    .heron-payment-gateway-qr-container-qr-video {
        .OnTablet({
            width:50vh;
            height: 50vh;
        })
}

}